import React, { useState, useEffect } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useTags from "../../hooks/useTags";

const TicketsTagSelect = ({ tag = "", filialId = "", userId="", onChange }) => {
	const handleChange = e => {
		setSelectedTag(e.target.value);
		if (onChange) onChange(e.target.value);
	};

	const [tags, setTags] = useState([]);
	const [selectedTag, setSelectedTag] = useState(tag);
	const { findAll: findAllTags } = useTags(userId, filialId);

	useEffect(() => {
		const init = async () => {
			const listTags = await findAllTags(userId, filialId);
			setTags(listTags);

		}
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div style={{ width: 250, marginTop: -4 }}>
			<FormControl fullWidth margin="dense">
				<Select
					displayEmpty
					variant="outlined"
					value={selectedTag}
					onChange={handleChange}
					renderValue={(selected) => {
						const selectedTagObj = tags.find((tag) => tag.id === selected);
						return selectedTagObj ? selectedTagObj.descricao : "Filtrar por etiqueta";
					}}
				>
					<MenuItem value="">
						<em>Sem seleção</em>
					</MenuItem>
					{tags.map((tag) => (
						<MenuItem key={tag.id} value={tag.id}>{tag.descricao}</MenuItem>
					))}
				</Select>
			</FormControl>
		</div>
	);
};

export default TicketsTagSelect;

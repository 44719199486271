import React from "react";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { Checkbox, ListItemText } from "@material-ui/core";

const TicketsWhatsappSelect = ({
	whatsApps,
	selectedWhatsAppIds = [],
	onChange,
}) => {
	const handleChange = (e) => {
		const value = e.target.value;

		if (value.includes("toggle-all")) {
			// Verifica se todos estão selecionados; se sim, desmarca tudo, caso contrário, seleciona todos
			if (selectedWhatsAppIds.length === whatsApps.length) {
				onChange([]);
			} else {
				onChange(whatsApps.map((whatsApp) => whatsApp.id));
			}
		} else {
			onChange(value);
		}
	};

	return (
		<div style={{ width: 250, marginTop: -4 }}>
			<FormControl fullWidth margin="dense">
				<Select
					multiple
					displayEmpty
					variant="outlined"
					value={selectedWhatsAppIds}
					onChange={handleChange}
					MenuProps={{
						anchorOrigin: {
							vertical: "bottom",
							horizontal: "left",
						},
						transformOrigin: {
							vertical: "top",
							horizontal: "left",
						},
						getContentAnchorEl: null,
					}}
					renderValue={() => "Filtrar por conexão"}
				>
					<MenuItem dense value="toggle-all">
						<Checkbox
							color="primary"
							checked={selectedWhatsAppIds.length === whatsApps.length}
							indeterminate={
								selectedWhatsAppIds.length > 0 && selectedWhatsAppIds.length < whatsApps.length
							}
						/>
						<ListItemText
							primary={"Selecionar Tudo"}
						/>
					</MenuItem>

					{whatsApps?.length > 0 &&
						whatsApps.map((whatsApp) => (
							<MenuItem dense key={whatsApp.id} value={whatsApp.id}>
								<Checkbox
									checked={selectedWhatsAppIds.includes(whatsApp.id)}
									color="primary"
								/>
								<ListItemText primary={whatsApp.name} />
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</div>
	);
};

export default TicketsWhatsappSelect;

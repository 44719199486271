import api from "../../services/api";

const useQueues = (userId) => {
    let data = {};
    data.userId = userId;
	const findAll = async () => {
        const { data } = await api.get(`/queueUser/${userId}`);
        return data;
    }

	return { findAll };
};

export default useQueues;

import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import FormControl from "@material-ui/core/FormControl";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: '100%',
	},
	marginTop: {
		marginTop: theme.spacing(2),
	},
}));

const NewTicketModal = ({ modalOpen, onClose }) => {
	const [dataInicio, setDataInicio] = useState('');
	const [dataFim, setDataFim] = useState('');
	const [resetModal, setResetModal] = useState(false);
	const classes = useStyles();

	const handleClose = () => {
		onClose({ dataInicio, dataFim });
		setResetModal(false);
	};

	const handleCancelTicket = () => {
		setDataInicio("");
		setDataFim("");
		setResetModal(true);
	};

	useEffect(() => {
		if (resetModal) {
			handleClose();
		}
	}, [dataInicio, dataFim, resetModal]); // Executa handleClose após resetModal

	return (
		<Dialog open={modalOpen} onClose={handleClose}>
			<DialogTitle id="form-dialog-title">
				Filtrar lead por data
			</DialogTitle>
			<DialogContent dividers>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<TextField
						label="Data inicial"
						variant="outlined"
						type="date"
						fullWidth
						InputLabelProps={{ shrink: true }}
						className={classes.marginTop}
						value={dataInicio}
						onChange={(e) => setDataInicio(e.target.value)}
					/>
				</FormControl>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<TextField
						label="Data final"
						variant="outlined"
						type="date"
						fullWidth
						InputLabelProps={{ shrink: true }}
						className={classes.marginTop}
						value={dataFim}
						onChange={(e) => setDataFim(e.target.value)}
					/>
				</FormControl>
				<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
					<Button
						onClick={() => {
							setDataInicio("");
							setDataFim("");
						}}
						color="secondary"
						variant="outlined"
					>
						Limpar filtros
					</Button>
				</FormControl>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => handleCancelTicket()}
					color="secondary"
					variant="outlined"
				>
					Cancelar
				</Button>
				<ButtonWithSpinner
					variant="contained"
					type="button"
					onClick={() => handleClose()}
					color="primary"
				>
					Filtrar
				</ButtonWithSpinner>
			</DialogActions>
		</Dialog>
	);
};

export default NewTicketModal;

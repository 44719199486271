import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import useTags from "../../hooks/useTags";

// const useStyles = makeStyles((theme) => ({
// 	maxWidth: {
// 		width: "100%",
// 	},
// }));

const filterOptions = createFilterOptions({
	trim: true,
});

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: '100%',
	},
	marginTop: {
		marginTop: theme.spacing(2), // Ajuste o valor conforme necessário
	},
}));

// const TransferTicketModal = ({ modalOpen, onClose, ticketid }) => {
const TransferTicketModal = ({ modalOpen, onClose, ticketid, userId, filialId, leadId, obs, nomeT, agenda, etapa, tag }) => {
	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [queues, setQueues] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState('');
	const classes = useStyles();
	const { findAll: findAllQueues } = useQueues(userId);
	const [agendamento, setAgendamento] = useState('');
	const [observacao, setObservacao] = useState('');
	const [nome, setNome] = useState('');

	const [tags, setTags] = useState([]);
	// const [allTags, setAllTags] = useState([]);
	const [selectedTag, setSelectedTag] = useState('');
	const { findAll: findAllTags } = useTags(userId, filialId);

	useEffect(() => {
		const init = async () => {
			const list = await findAllQueues(userId);
			setAllQueues(list);
			setQueues(list);
			setObservacao(obs || '');
			setNome(nomeT || '');
			setAgendamento(converteDateTime(agenda || ''));
			setSelectedQueue(etapa);

			const listTags = await findAllTags(userId, filialId);
			// setAllTags(listTags);
			setTags(listTags);
			setSelectedTag(tag);

		}
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const converteDateTime = (dateTimeString) => {
		const date = new Date(dateTimeString);
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');

		return `${year}-${month}-${day}T${hours}:${minutes}`;
	};

	// useEffect(() => {
	// 	if (!modalOpen || searchParam.length < 3) {
	// 		setLoading(false);
	// 		return;
	// 	}
	// 	setLoading(true);
	// 	const delayDebounceFn = setTimeout(() => {
	// 		const fetchUsers = async () => {
	// 			try {
	// 				const { data } = await api.get("/users/", {
	// 					params: { searchParam },
	// 				});
	// 				setOptions(data.users);
	// 				setLoading(false);
	// 			} catch (err) {
	// 				setLoading(false);
	// 				toastError(err);
	// 			}
	// 		};

	// 		fetchUsers();
	// 	}, 500);
	// 	return () => clearTimeout(delayDebounceFn);
	// }, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedUser(null);
	};

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticketid) return;
		setLoading(true);
		try {
			let data = {};

			// if (selectedUser) {
			// 	data.userId = selectedUser.id
			// }

			if (selectedQueue && selectedQueue !== null) {
				data.queueId = selectedQueue
				data.userId = userId;
				data.leadId = leadId;
				data.observacao = observacao;
				data.name = nome;
				if (selectedTag) {
					data.tagId = selectedTag;
				}
				if (!isNaN(new Date(agendamento).getTime())) {
					data.agendamento = agendamento;
				}
				// if (!selectedUser) {
				// 	data.status = 'pending';
				// 	data.userId = null;
				// }
			}
			await api.put(`/ticketsetapa/${ticketid}`, data);

			setLoading(false);
			handleClose();
			history.push(`/tickets/${ticketid}`);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					{/*<Autocomplete
						style={{ width: 300, marginBottom: 20 }}
						getOptionLabel={option => `${option.name}`}
						onChange={(e, newValue) => {
							setSelectedUser(newValue);
							if (newValue != null && Array.isArray(newValue.queues)) {
								setQueues(newValue.queues);
							} else {
								setQueues(allQueues);
								setSelectedQueue('');
							}
						}}
						options={options}
						filterOptions={filterOptions}
						freeSolo
						autoHighlight
						noOptionsText={i18n.t("transferTicketModal.noOptions")}
						loading={loading}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("transferTicketModal.fieldLabel")}
								variant="outlined"
								required
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>*/}

					<FormControl variant="outlined" className={classes.maxWidth}>
						<TextField
							label="Nome do lead"
							variant="outlined"
							multiline
							fullWidth
							className={classes.marginTop}
							value={nome}
							onChange={(e) => setNome(e.target.value)}
						/>
					</FormControl>
					<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
						<InputLabel>Incluir TAG</InputLabel>
						<Select
							value={selectedTag}
							onChange={(e) => setSelectedTag(e.target.value)}
							label="Incluir TAG"
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{tags.map((tag) => (
								<MenuItem key={tag.id} value={tag.id}>{tag.descricao}</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
						<InputLabel>Etapa do lead</InputLabel>
						<Select
							value={selectedQueue}
							onChange={(e) => setSelectedQueue(e.target.value)}
							label="Etapa do lead"
						>
							{/* <MenuItem value={''}>&nbsp;</MenuItem> */}
							{queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>
					<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
						<TextField
							label="Data de agendamento"
							variant="outlined"
							type="datetime-local"
							fullWidth
							InputLabelProps={{ shrink: true }}
							className={classes.marginTop}
							value={agendamento}
							onChange={(e) => setAgendamento(e.target.value)}
						/>
					</FormControl>
					<FormControl variant="outlined" className={`${classes.maxWidth} ${classes.marginTop}`}>
						<TextField
							label="Observações"
							variant="outlined"
							multiline
							fullWidth
							className={classes.marginTop}
							value={observacao}
							onChange={(e) => setObservacao(e.target.value)}
						/>
					</FormControl>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;
